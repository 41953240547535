import { Canvas, useFrame } from "@react-three/fiber";
import { Environment, Lightformer, ContactShadows, OrbitControls, Html, Plane, Grid } from "@react-three/drei";
import { Effects } from "./Effects";
import Navi from "./Navi";
import { Vector3 } from "three";
import { useEffect, useRef, useState } from "react";
import HUD from "./Sections/HUD";
import Gate from "./Sections/Gate";
import { useEarthoOne } from "@eartho/one-client-react";
import nlp from "compromise/two";
import PracticeBot from "./Characters/PracticeBot";
import ProgramBot from "./Characters/ProgramBot";
import Arena from "./Sections/Arena";
import Panel from "./Components/Panel";
import { Howl } from 'howler';
import * as Comlink from 'comlink';

import shoot from "./Assets/Sounds/shoot.mp3";
import shootHit from "./Assets/Sounds/shoot-hit.mp3";
import shootCharged from "./Assets/Sounds/shoot-charged.mp3";

const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: "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",
});

//GUN JS
//window.db

let attackTimeout = null;
const shootSound = new Howl({
  src: [shoot],
  preload: true,
  autoDecode: true,
});
const shootHitSound = new Howl({
  src: [shootHit],
  preload: true,
  autoDecode: true,
});

const shootChargedSound = new Howl({
  src: [shootCharged],
  preload: true,
  autoDecode: true,
});

export default function App() {
  const { isLoading, isConnected, error, user, connectWithPopup, logout } = useEarthoOne();
  const orbitRef = useRef();
  const audioRef = useRef();

  const netUser = window.db.user().recall({ sessionStorage: true });

  const [navi, setNavi] = useState({});
  const [enemy, setEnemy] = useState({});

  const [naviEdit, setNaviEdit] = useState(false);

  function updateNavi(navi, cb, skipRefresh) {
    console.log("updateNavi", navi);
    setNavi(navi);
    localStorage.setItem("navi", JSON.stringify(navi));
    if (!skipRefresh) {
      setNaviEdit(true);
      setTimeout(() => {
        cb && cb();
        setNaviEdit(false);
      }, 1000);
    } else {
      cb && cb();
    }
  }

  const [area, setArea] = useState("HUB");

  const [isReady, setIsReady] = useState(false);

  const [cameraLookAtTarget, setCameraLookAtTarget] = useState([0, 3, 0.5]);
  const [cameraPosition, setCameraPosition] = useState([0, 3, 3]);

  const [activePage, setActivePage] = useState(null);

  function updateActivePage(page, cb) {
    setActivePage(page);
    cb && cb();
  }

  function createNavi(name) {
    if (name === "" || name === null) return;
    const navi = {
      userId: user.user.uid,
      name: name,
      birthDate: new Date().getTime(),
      conversation: [],
      color: "blue",
      VoiceId: "ai3-en-US-Austin",
      LanguageCode: "en-US",
    };
    updateNavi(navi);
  }

  function CameraControls({ to, target, lerping, controls }) {
    useFrame(({ camera }, delta) => {
      if (lerping) {
        camera.position.lerp(to, delta);
        controls.current.target.lerp(target, delta);
      } else {
        //smooth camera movement at 60 fps
        //console.log(delta);
      }
    });
  }

  function Authentication() {
    return (
      <Html className="content" rotation-y={Math.PI} position={[0, 10, 20]} transform occlude>
        <div className="wrapper" onPointerDown={(e) => e.stopPropagation()}>
          <div className="title">
            <h1 className="mb-20">Authenticate</h1>
            <button id="login" onClick={() => connectWithPopup({ access_id: "19w9K8CIlFr0cRHACzHc" })}>
              Log in
            </button>
          </div>
        </div>
      </Html>
    );
  }

  function NaviSetUp() {
    const [name, setName] = useState("");
    return (
      <Html className="content" rotation-y={Math.PI} position={[0, 5, 13]} transform occlude>
        <div className="wrapper" onPointerDown={(e) => e.stopPropagation()} style={{ height: 600, display: "flex", flexDirection: "column" }}>
          <div style={{ marginTop: 100 }}>
            <h1>Net Navi Name</h1>
            <h3 className="mb-20">Choose wisely</h3>
            <input
              value={name}
              placeholder="Name"
              onChange={(e) => {
                if (e.target.value.length > 10) return;
                setName(e.target.value);
              }}
            ></input>
            <label style={{ textAlign: "right", width: "100%", display: "block" }}>{name.length}/10</label>
            <button className="glowButton" style={{ marginTop: 250 }} onClick={() => createNavi(name)}>
              CREATE
            </button>
          </div>
        </div>
      </Html>
    );
  }

  useEffect(() => {

    if (!isLoading) {
      if (isConnected && user) {
        let savedNavi = localStorage.getItem("navi");
        if (savedNavi) {
          savedNavi = JSON.parse(savedNavi);
          console.log("SAVED NAVI", savedNavi);
          updateNavi({ ...navi, ...savedNavi });
        }
      }
    }
  }, [user]);

  const [currentAction, setCurrentAction] = useState("Talking");

  async function queryGPT3(prompt, cb) {
    const openai = new OpenAIApi(configuration);
    const params = {
      model: "gpt-4-0613",
      prompt,
      temperature: 0.7,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
    openai
      .createCompletion(params)
      .then((res) => {
        cb && cb(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function queryChatGPT(prompt, cb) {
    /* const possessedNouns = parsePromptForPossesiveNouns(prompt);
        const savedNouns = [];
        if (possessedNouns.length > 0) {
          for (let p = 0; p < possessedNouns.length; p++) {
            const savedNoun = await window.db.get('nouns').get(possessedNouns[p]).once();
            if (savedNoun) {
              savedNouns.push(savedNoun);
              window.db.get('nouns').get(possessedNouns[p]).put({ ...savedNoun, lastUsed: new Date().getTime() });
            } else {
              window.db.get('nouns').get(possessedNouns[p]).put({ name: possessedNouns[p], lastUsed: new Date().getTime() });
            }
          }
        } */
    const openai = new OpenAIApi(configuration);
    const conversationSnippet = [];
    let characterCount = 0;
    navi.conversation.push({ role: "user", content: prompt, timestamp: new Date().getTime() });
    let snipIndex = 0;
    let clipIndex = 0;
    for (let c = navi.conversation.length - 1; c >= 0; c--) {
      const message = navi.conversation[c];
      if (characterCount < 10000) {
        characterCount += message.content.length;
      } else {
        snipIndex = c;
        break;
      }
    }
    characterCount = 0;
    let overflowTimestamp = 0;
    if (snipIndex === 0) {
      for (let s = snipIndex; s >= 0; s--) {
        const message = navi.conversation[s];
        if (characterCount < 10000) {
          characterCount += message.content.length;
        } else {
          clipIndex = s;
          overflowTimestamp = message.timestamp;
          break;
        }
      }
    }

    for (let c = snipIndex; c < navi.conversation.length; c++) {
      const message = navi.conversation[c];
      conversationSnippet.push({ role: message.role, content: message.content });
    }

    const conversationOverflow = [];
    if (clipIndex !== 0) {
      for (let c = clipIndex; c < snipIndex; c++) {
        const message = navi.conversation[c];
        conversationOverflow.push(message);
      }

      const conversationString = conversationOverflow.map((message) => message.content).join("/n");
      const overflowSummary = await queryGPT3(`Summarize the following conversation between an AI network navigator and a human navigator: ${conversationString}`);
      conversationSnippet.unshift({ role: "system", content: overflowSummary, timestamp: new Date().getTime() });

      conversationSnippet.unshift({ role: "system", content: `The following conversation has been summarized on ${new Date(overflowTimestamp).toDateString()}` });
      navi.conversation = conversationSnippet;
      updateNavi(navi);
    } else {
      updateNavi(navi, false, true);
    }

    const model = "gpt-4-0613"; //"gpt-3.5-turbo";
    const savedDetails = JSON.parse(localStorage.getItem("personalDetails")) || [];
    const now = new Date();
    const functions = [
      {
        name: "save_personal_detail",
        description: "Save personal detail from conversation to keep in mind",
        parameters: {
          type: "object",
          properties: {
            detail: {
              type: "string",
              description: "detail to save",
            },
            relation: {
              type: "string",
              description: "relation to detail"
            },
            priority: {
              type: "number",
              description: "priority of detail"
            }
          },
          required: ["detail"],
        },
      },
      {
        name: "set_alarm",
        description: "Set an alarm",
        parameters: {
          type: "object",
          properties: {
            time: {
              type: "string",
              description: "time to set alarm",
            },
            message: {
              type: "string",
              description: "message to display when alarm goes off"
            }
          },
          required: ["time"],
        },
      },
      {
        name: "set_reminder",
        description: "Set a reminder",
        parameters: {
          type: "object",
          properties: {
            time: {
              type: "string",
              description: "time to set reminder",
            },
            message: {
              type: "string",
              description: "message to display when reminder goes off"
            }
          },
          required: ["time"],
        },
      },
      {
        name: "save_task",
        description: "Save a task",
        parameters: {
          type: "object",
          properties: {
            task: {
              type: "string",
              description: "task to save",
            },
            dueDate: {
              type: "string",
              description: "due date of task"
            },
            description: {
              type: "string",
              description: "description of task"
            }
          },
          required: ["task"],
        },
      },
      {
        name: "play_music",
        description: "Play a song",
        parameters: {
          type: "object",
          properties: {
            song: {
              type: "string",
              description: "song to play",
            },
            artist: {
              type: "string",
              description: "artist of song"
            }
          },
          required: ["song"],
        },
      },
    ];
    const messages = [
      {
        role: "system",
        content:
          "You are a network navigator being rendered as a 3d avatar that can move and battle within a 3d rendered world within a progressive web application named personal electronic terminal (PET) tasked with helping your operator use the cyber network to navigate a 3d rendered cyber world and assist with daily life.",
      },
      { role: "assistant", content: "I'm  your network navigator named " + navi.name },
      { role: "user", content: "I'm your operator named " + user.user.firstName },
      area != "HUB"
        ? { role: "system", content: "Currently in the " + area + " of the cyber world, current real world date is " + now.toLocaleDateString() + "." }
        : {
          role: "system",
          content: "Currently in the hub, there are 5 actions available [customize, explore, talk, train, configure], currently chosen: " + currentAction + ".",
        },
      {
        role: "system",
        content: `Keep in mind the following personal details: ${savedDetails.map((detail) => `${detail.detail} as related to ${detail.relation}`).join(", ")}.`
      },
      {
        role: "system",
        content:
          "IMPORTANT: The current real-time clock regardles of time in chats is: " +
          now.toString() +
          ". The following is a snippet of the conversation ordered from latest to oldest, stopping at the last 10000 characters, respond contextually with an intent to engage with and learn from your operator.",
      },
      ...conversationSnippet,
    ];
    openai
      .createChatCompletion({ model, messages, functions })
      .then((res) => {
        const msg = res.data.choices[0].message;
        if (msg.function_call) {
          let parameters = msg.function_call.arguments;
          try {
            parameters = JSON.parse(parameters);
            switch (msg.function_call.name) {
              case "save_personal_detail":
                const detail = parameters.detail;
                const relation = parameters.relation;
                const priority = parameters.priority;
                savePersonalDetail(detail, relation, priority);
                break;
              case "set_alarm":
                const time = parameters.time;
                const message = parameters.message;
                setAlarm(time, message);
                break;
              case "set_reminder":
                const reminderTime = parameters.time;
                const reminderMessage = parameters.message;
                setReminder(reminderTime, reminderMessage);
                break;
              case "save_task":
                const task = parameters.task;
                const dueDate = parameters.dueDate;
                const description = parameters.description;
                saveTask(task, dueDate, description);
                break;
              case "play_music":
                const song = parameters.song;
                const artist = parameters.artist;
                playMusic(song, artist);
                break;

            }

            messages.push({ role: "system", content: "Function " + msg.function_call.name + " executed successfully." });

            openai
              .createChatCompletion({ model, messages, functions })
              .then((res) => {
                const msg2 = res.data.choices[0].message;
                if (msg2.content) {
                  navi.conversation.push({ role: "assistant", content: msg2.content });
                  cb && cb(msg2.content);
                } else {
                  cb && cb("");
                }
              })

          } catch (e) {
            console.log(e);
          }
        } else if (msg.content) {
          navi.conversation.push({ role: "assistant", content: res.data.choices[0].message.content });
          /* let doc = nlp(res.data.choices[0].message.content);
let str = doc.match(`(?:(?:add|create|schedule|set|book)(?: a| an| the| my)? (?:(?:#Ordinal|#Cardinal)? ?(?:#Unit|#Duration)? ?(?:#Adjective )?(?:#Noun(?:s|es)?)?(?: #Preposition(?: the)?(?: #ProperNoun)?(?: #Adjective)?(?: #Noun(?:s|es)?)?)?(?: #Preposition(?: #Determiner)?(?: #ProperNoun)?(?: #Adjective)?(?: #Noun(?:s|es)?)?)?)|(?:(?:launch|open|start)(?: #Determiner)?(?: #Noun)?(?: #Preposition)?(?: #ProperNoun)?(?: #Adjective)?(?: #Noun(?:s|es)?)?)|(?:(?:make|take|note)(?: #Determiner)?(?: #Noun)?(?: #Preposition)?(?: #Determiner)?(?: #Noun)?))`).text();
console.log("VERB", str); */
          cb && cb(res.data.choices[0].message.content);
        }
      })
      .catch((err) => {
        console.log(err);
        cb && cb("");
      });
  }

  const [battleOver, setBattleOver] = useState(false);

  const [naviPosition, setNaviPosition] = useState([0, 0, 0]);
  const [naviRotation, setNaviRotation] = useState([0, 0, 0]);
  const [naviHealth, setNaviHealth] = useState(100);
  const [naviAction, setNaviAction] = useState("idle");
  const [enemyPosition, setEnemyPosition] = useState([0, 0, 12.25]);
  const [enemyRotation, setEnemyRotation] = useState([0, 0, 0]);
  const [enemyHealth, setEnemyHealth] = useState(100);
  const [sideCamera, setSideCamera] = useState(true);

  function move(direction) {
    if (sideCamera) {
      switch (direction) {
        case "up":
          if (naviPosition[0] < 4) {
            setNaviPosition([naviPosition[0] + 4, 0, naviPosition[2]]);
          }
          break;
        case "down":
          if (naviPosition[0] > -4) {
            setNaviPosition([naviPosition[0] - 4, 0, naviPosition[2]]);
          }
          break;
        case "right":
          if (naviPosition[2] < 4) {
            setNaviPosition([naviPosition[0], 0, naviPosition[2] + 4]);
          }
          break;
        case "left":
          if (naviPosition[2] > -4) {
            setNaviPosition([naviPosition[0], 0, naviPosition[2] - 4]);
          }
          break;
      }


    } else {
      switch (direction) {
        case "left":
          if (naviPosition[0] < 4) {
            setNaviPosition([naviPosition[0] + 4, 0, naviPosition[2]]);
          }
          break;
        case "right":
          if (naviPosition[0] > -4) {
            setNaviPosition([naviPosition[0] - 4, 0, naviPosition[2]]);
          }
          break;
        case "up":
          if (naviPosition[2] < 4) {
            setNaviPosition([naviPosition[0], 0, naviPosition[2] + 4]);
          }
          break;
        case "down":
          if (naviPosition[2] > -4) {
            setNaviPosition([naviPosition[0], 0, naviPosition[2] - 4]);
          }
          break;
      }

    }
  }

  function fireShot(charged, cb) {
    //from navi shoot forward, if enemy is in front of navi, hit enemy
    //if enemy is hit, enemy health -= 1 or -= 10 if charged
    //if enemy health <= 0, enemy dies
    attackTimeout && clearTimeout(attackTimeout);
    setNaviAction("shootDraw");
    let targetHit = false;
    let eh = enemyHealth;
    if (naviPosition[0] == enemyPosition[0] && eh > 0) {
      let damage = charged ? 10 : 1;
      if (eh - damage < 0) {
        damage = enemyHealth;
      }
      targetHit = true;
      eh -= damage;
      setEnemyHealth(eh);
    }
    if (eh <= 0) {
      setBattleOver(true);
    }

    if (charged) {
      shootChargedSound.play();
    } else if (targetHit) {
      shootHitSound.play();
    } else {
      shootSound.play();
    }

    setTimeout(() => {
      cb && cb();
      //setNaviAction("shootHolster");
      attackTimeout = setTimeout(() => {
        setNaviAction("idle");
      }, 250);
    }, 250);
  }

  function enemyAttack(cb) {
    let targetHit = false;
    if (naviPosition[0] == enemyPosition[0] && naviHealth > 0) {
      let damage = 1;
      if (naviHealth - damage < 0) {
        damage = naviHealth;
      }
      targetHit = true;
      setNaviHealth(naviHealth - damage);
    }
    if (naviHealth <= 0) {
      setBattleOver(true);
    }

    if (targetHit) {
      shootHitSound.play();
    } else {
      shootSound.play();
    }

    setTimeout(() => {
      cb && cb();
    }, 500);
  }

  function resetBattle() {
    setNaviPosition([0, 0, 0]);
    setNaviHealth(100);
    setEnemyHealth(100);
    setBattleOver(false);
  }

  function savePersonalDetail(detail, relation, priority) {
    const savedDetails = JSON.parse(localStorage.getItem("personalDetails")) || [];
    savedDetails.push({ detail, relation, priority });
    localStorage.setItem("personalDetails", JSON.stringify(savedDetails));
  }

  function setAlarm(time, message) {
    const alarms = JSON.parse(localStorage.getItem("alarms")) || [];
    //create alarm job
    const alarmTime = new Date(time).getTime();
    const now = new Date().getTime();
    if (alarmTime > now) {
      setTimeout(() => {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification("Alarm", {
            body: message,
            icon: "/cynet.png",
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            tag: "alarm",
          });
        });
      }, alarmTime - now);
    }

    alarms.push({ time, message });
    localStorage.setItem("alarms", JSON.stringify(alarms));
  }

  function setReminder(time, message) {
    const reminders = JSON.parse(localStorage.getItem("reminders")) || [];
    //create reminder job
    const reminderTime = new Date(time).getTime();
    const now = new Date().getTime();
    if (reminderTime > now) {
      setTimeout(() => {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification("Reminder", {
            body: message,
            icon: "/cynet.png",
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            tag: "reminder",
          });
        });
      }, reminderTime - now);
    }

    reminders.push({ time, message });
    localStorage.setItem("reminders", JSON.stringify(reminders));
  }

  function saveTask(task, dueDate, description) {
    const tasks = JSON.parse(localStorage.getItem("tasks")) || [];
    tasks.push({ task, dueDate, description });
    localStorage.setItem("tasks", JSON.stringify(tasks));
  }

  function playMusic(song, artist) {
    const music = JSON.parse(localStorage.getItem("music")) || [];
    music.push({ song, artist });
    localStorage.setItem("music", JSON.stringify(music));
  }

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <Gate navi={navi} isReady={isReady} setIsReady={setIsReady} setCameraLookAtTarget={setCameraLookAtTarget} setCameraPosition={setCameraPosition} isLoading={isLoading} />
      <HUD
        isReady={isReady}
        setIsReady={setIsReady}
        setCameraLookAtTarget={setCameraLookAtTarget}
        setCameraPosition={setCameraPosition}
        isConnected={isConnected}
        navi={navi}
        setNavi={setNavi}
        updateNavi={updateNavi}
        queryGPT3={queryGPT3}
        queryChatGPT={queryChatGPT}
        setActivePage={updateActivePage}
        currentAction={currentAction}
        setCurrentAction={setCurrentAction}
        area={area}
        setArea={setArea}
        setEnemy={setEnemy}
        move={move}
        fireShot={fireShot}
        naviHealth={naviHealth}
        enemyHealth={enemyHealth}
        resetBattle={resetBattle}
        audioRef={audioRef}
        battleOver={battleOver}
      />

      <Canvas gl={{ logarithmicDepthBuffer: true, antialias: false }} dpr={[1, 1.5]} camera={{ position: cameraPosition, fov: 60 }}>
        {!naviEdit && (
          <Navi
            setCameraLookAtTarget={setCameraLookAtTarget}
            setCameraPosition={setCameraPosition}
            cameraActive={area == "arena"}
            navi={navi}
            netUser={netUser}
            queryGPT3={queryGPT3}
            followDirections={true}
            position={naviPosition}
            rotation={naviRotation}
            action={naviAction}
          />
        )}
        {!isConnected ? <Authentication /> : isConnected && !navi.userId ? <NaviSetUp /> : !naviEdit && activePage}

        <hemisphereLight intensity={0.5} />
        <ContactShadows resolution={1024} frames={1} position={[0, 0, 0]} scale={15} blur={0.5} opacity={1} far={20} />

        {area == "HUB" && (
          <>
            <Panel position={[0, 0, 0]} color={navi.color} />
            <Panel position={[4, 0, 0]} color={navi.color} />
            <Panel position={[-4, 0, 0]} color={navi.color} />
            <Panel position={[0, 0, -4]} color={navi.color} />
            <Panel position={[4, 0, -4]} color={navi.color} />
            <Panel position={[-4, 0, -4]} color={navi.color} />
            <Panel position={[0, 0, 4]} color={navi.color} />
            <Panel position={[4, 0, 4]} color={navi.color} />
            <Panel position={[-4, 0, 4]} color={navi.color} />
          </>
        )}

        {area == "arena" && <>

          <Panel position={[0, 0, 0]} color={navi.color} />
          <Panel position={[4, 0, 0]} color={navi.color} />
          <Panel position={[-4, 0, 0]} color={navi.color} />
          <Panel position={[0, 0, -4]} color={navi.color} />
          <Panel position={[4, 0, -4]} color={navi.color} />
          <Panel position={[-4, 0, -4]} color={navi.color} />
          <Panel position={[0, 0, 4]} color={navi.color} />
          <Panel position={[4, 0, 4]} color={navi.color} />
          <Panel position={[-4, 0, 4]} color={navi.color} />

          {/* enemy */}

          <PracticeBot
            position={enemyPosition}
            rotation={[0, Math.PI, 0]}
            bottomleftPanelPosition={[-4, 0, 8.25]}
            topRightPanelPosition={[4, 0, 16.25]}
            setPosition={setEnemyPosition}
            health={enemyHealth}
            fire={enemyAttack}
            naviPosition={naviPosition}
          />
          <Panel position={[0, 0, 12.25]} color={"#fff"} />
          <Panel position={[4, 0, 12.25]} color={"#fff"} />
          <Panel position={[-4, 0, 12.25]} color={"#fff"} />
          <Panel position={[0, 0, 8.25]} color={"#fff"} />
          <Panel position={[4, 0, 8.25]} color={"#fff"} />
          <Panel position={[-4, 0, 8.25]} color={"#fff"} />
          <Panel position={[0, 0, 16.25]} color={"#fff"} />
          <Panel position={[4, 0, 16.25]} color={"#fff"} />
          <Panel position={[-4, 0, 16.25]} color={"#fff"} />

        </>}

        {/* We're building a cube-mapped environment declaratively.
          Anything you put in here will be filmed (once) by a cubemap-camera
          and applied to the scenes environment, and optionally background. */}
        <Environment resolution={512}
          background={"#fff"}
        >
          {/* Ceiling */}
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -7]} scale={[10, 1, 1]} />
          {/* <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} /> */}
          {/* Sides */}
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />
          {/* Key */}
          <Lightformer form="ring" color="white" intensity={30} scale={2} position={[0, 8, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
        </Environment>
        <Effects />
        <OrbitControls ref={orbitRef} enablePan={false} enableZoom={true} target={cameraLookAtTarget} />
        <CameraControls to={new Vector3(...cameraPosition)} target={new Vector3(...cameraLookAtTarget)} lerping={true} controls={orbitRef} />
      </Canvas>
      <audio ref={audioRef}></audio>
    </div>
  );
}
