import React, { useState } from 'react';

const Recorder = ({ idleContent, recordingContent, callback }) => {
    const [recorder, setRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [isReplying, setIsReplying] = useState(false);
    const [processedChunks, setProcessedChunks] = useState([]);

    const mimeType = "audio/mp3";
    const fileName = "recording.mp3";

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.ondataavailable = e => {
                    const newAudioChunks = [...audioChunks, e.data];
                    setAudioChunks(newAudioChunks);
                    if (newAudioChunks.length > 100) {
                        const audioBlob = new Blob(newAudioChunks, { type: mimeType });
                        const audioUrl = URL.createObjectURL(audioBlob);

                        const file = new File([audioBlob], fileName, { type: mimeType });
                        processData(file);
                        //setAudioChunks([]);
                    } else if (mediaRecorder.state === "inactive") {
                        const audioBlob = new Blob(newAudioChunks, { type: mimeType });
                        const audioUrl = URL.createObjectURL(audioBlob);

                        const file = new File([audioBlob], fileName, { type: mimeType });
                        sendData(file);
                    }
                };
                setRecorder(mediaRecorder);
                setIsRecording(true);
                mediaRecorder.start();
            })
            .catch(error => console.error('getUserMedia error:', error));
    };

    const stopRecording = () => {
        if (recorder) {
            recorder.stop();
            setIsRecording(false);
        }
    };

    const sendData = async (data) => {
        const formData = new FormData();
        formData.append('model', 'whisper-1');
        formData.append('file', data, 'recording.mp3');

        const token = "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"
        setIsReplying(true);
        try {
            const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            }).then(response => response.json());
            console.log('Audio sent to API:', response);

            response && callback(response.text, () => {
                setIsReplying(false);
            });

            setAudioChunks([]);

        } catch (error) {
            console.error('Error sending audio to API:', error);
            setIsReplying(false);
            //sendData(data);
        }
    };

    const processData = async (data) => {
        const formData = new FormData();
        formData.append('model', 'whisper-1');
        formData.append('file', data, 'recording.mp3');

        const token = "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"
        try {
            const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            }).then(response => response.json());
            console.log('Audio sent to API:', response);
            const processData = [...processedChunks, response.text];
            setProcessedChunks(processData);

            setAudioChunks([]);

        } catch (error) {
            console.error('Error sending audio to API:', error);
            setIsReplying(false);
            //sendData(data);
        }

    }

    return (
        <div
            style={{ position: 'relative' }}
            onClick={isRecording ? stopRecording : startRecording}
            /* onMouseDown={startRecording}
            onMouseUp={stopRecording}
            onTouchStart={startRecording}
            onTouchEnd={stopRecording} */>
            {isReplying ? <span className="thinking"></span> : isRecording ? recordingContent : idleContent}
        </div>
    );
};

export default Recorder;
