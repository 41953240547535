import React, { Component, Fragment } from "react";
import Model from "./Model";
import Particles from "./Particles";
import ChargeShot from "./Components/ChargeShot";
import { Warrior } from "./Characters/Warrior";

class Navi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentTask: "",
            action: "idle",
            color: props.navi.color,
        };
    }

    render() {
        const { action, position, rotation, cameraActive, navi } = this.props;
        const { loading, generating } = this.state;

        if (navi.userId && loading) {
            this.setState({ loading: false, generating: true }, () => {
                setTimeout(() => {
                    this.setState({ generating: false });
                }, 1000);
            });
        }

        return (
            <Fragment>
                {navi.userId && !generating && (
                    /*  <Model
                         navi={navi}
                         selectedAction={action}
                         position={position}
                         rotation={rotation}
                         cameraActive={cameraActive}
                     /> */
                    <Warrior />
                )}
                {(loading || generating) && <Particles count={3} distance={1} generating={generating} />}
            </Fragment>
        );
    }
}

export default Navi;
