import React from "react";

const Panel = ({ position, color }) => {

    return (<>
        <mesh scale={4.25} position={[position[0], -.25, position[2]]} rotation={[0, 0, 0]}>
            <boxGeometry args={[1, 0.1, 1]} />
            <meshStandardMaterial color={"#444"} roughness={0.65} />
        </mesh>
        <mesh scale={3} position={position} rotation={[-Math.PI / 2, 0, Math.PI / 4]}>
            <ringGeometry args={[0.9, 1, 4, 1]} />
            <meshStandardMaterial color={color} roughness={0.75} />
        </mesh>
    </>);
}

export default Panel;