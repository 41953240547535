import React, { Component } from "react";

class Gate extends Component {

    state = {
        accessAllowed: false,
    }

    render() {
        const { accessAllowed } = this.state;
        const { isReady, setIsReady, isLoading } = this.props;
        return <section>
            <header className={`${isReady ? 'ready' : ''}`}>
                <div className="box">
                    <div className="title">
                        <h1>Cyber Netw<span onClick={() => this.setState({ accessAllowed: true })}>o</span>rk</h1>
                        <h2 style={{ textAlign: 'center', lineHeight: 1.5 }}>Personal Assistant Link</h2>
                    </div>
                </div>
                <div className="curve posRel">
                    <div className="left"><div></div></div>
                    <div className="center"></div>
                    <div className="right"><div></div></div>
                </div>
            </header>
            <footer className={`${isReady ? 'ready' : ''}`}>
                <div className="curve">
                    <div className="left"><div></div></div>
                    <div className="center"></div>
                    <div className="right"><div></div><div></div></div>
                </div>
                <div className="box">
                    <div className="title">
                        {accessAllowed && <button
                            className={`mainButton ${isLoading ? 'disabled' : ''}`}
                            onClick={() => {
                                setIsReady(true);

                                Notification.requestPermission().then(function (result) {
                                    if (result === "granted") {
                                        console.log("Notification permission granted.");
                                    }
                                });
                            }}>Enter</button>}
                    </div>
                </div>
            </footer>
        </section>
    }
}

export default Gate;