import * as THREE from 'three'
import React, { useRef, useMemo } from 'react'
import { useFrame, useThree } from '@react-three/fiber'

export default function Particles({ count, distance }) {
    const mesh = useRef()
    const light = useRef()
    const { size, viewport } = useThree()
    const aspect = size.width / viewport.width

    const dummy = useMemo(() => new THREE.Object3D(), [])
    // Generate some random positions, speed factors and timings
    const particles = useMemo(() => {
        const temp = []
        for (let i = 0; i < count; i++) {
            const t = Math.random() * distance
            const factor = 2 + Math.random() * distance
            const speed = 0.01 + Math.random() / distance * 2
            const xFactor = -5 + Math.random() * distance
            const yFactor = -5 + Math.random() * distance
            const zFactor = -5 + Math.random() * distance
            temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
        }
        return temp
    }, [count])
    // The innards of this hook will run every frame
    useFrame((state, delta) => {
        // Makes the light follow the mouse
        //light.current.position.set(0, 3, .5)
        // Run through the randomized data to calculate some movement
        particles.forEach((particle, i) => {
            let { t, factor, speed, xFactor, yFactor, zFactor } = particle
            // There is no sense or reason to any of this, just messing around with trigonometric functions
            t = particle.t += speed / 2
            const a = Math.cos(t) + Math.sin(t * 1) / 10
            const b = Math.sin(t) + Math.cos(t * 2) / 10
            const s = Math.cos(t) * .1
            //particle.mx += (mouse.current[0] - particle.mx) * 0.01
            //particle.my += (mouse.current[1] * -1 - particle.my) * 0.01
            // Update the dummy object
            if (i == 0) {
                dummy.position.set(0, 3, .5)
            } else {
                //spin around first point
                /*  dummy.position.set(
                     Math.cos(t) * 2,
                     0
                     Math.cos(t) * 2
                 ) */
            }
            //dummy.position.set(0, 3, .5)
            //dummy.scale.set(s, s, s)
            //slowly spin the particles
            dummy.rotation.set(0, dummy.rotation.y + delta / 4, 0)

            //dummy.rotation.set(s * 5, s * 5, s * 5)
            //dummy.rotation.set(s * 5, s * 5, s * 5)
            dummy.updateMatrix()
            // And apply the matrix to the instanced item
            mesh.current.setMatrixAt(i, dummy.matrix)
        })
        mesh.current.instanceMatrix.needsUpdate = true
    })
    return (
        <>
            <instancedMesh ref={mesh} args={[null, null, count]}>
                <dodecahedronGeometry args={[0.5, 0]} />
                <meshNormalMaterial color="#00ffff" />
            </instancedMesh>
        </>
    )
}
