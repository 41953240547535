import { useEffect, useRef, useState } from "react";
import { Sphere } from "@react-three/drei";


const ChargeShot = ({ charging, chargePos, forwardRotation, envObjects }) => {
    const [charge, setCharge] = useState(0);
    const [launchedPos, setLaunchedPos] = useState(chargePos);
    const [distanceTraveled, setDistanceTraveled] = useState(0);
    let launchInterval = useRef(null);
    let charginInterval = useRef(null);
    let c = 0;
    charginInterval = setInterval(() => {
        if (charging) {
            if (charge < 0.5) {
                setCharge(c += 0.01);
            }
        } else if (charge > 0) {
            charginInterval = null;
            setLaunchedPos(chargePos);
            let lc = chargePos;
            launchInterval = setInterval(() => {
                //launch forward
                setLaunchedPos([lc[0], lc[1], lc[2] += .1]);
                setDistanceTraveled(distanceTraveled + 0.1);
                //check for collision
                for (let i = 0; i < envObjects.length; i++) {
                    if (envObjects[i].position[0] - 0.5 < launchedPos[0] && envObjects[i].position[0] + 0.5 > launchedPos[0] &&
                        envObjects[i].position[1] - 0.5 < launchedPos[1] && envObjects[i].position[1] + 0.5 > launchedPos[1] &&
                        envObjects[i].position[2] - 0.5 < launchedPos[2] && envObjects[i].position[2] + 0.5 > launchedPos[2]) {
                        console.log("hit");
                        clearInterval(launchInterval);
                        setLaunchedPos(null);
                        setCharge(0);
                    }
                }
                if (distanceTraveled > 10) {
                    clearInterval(launchInterval);
                    setLaunchedPos(null);
                    setCharge(0);
                }
            }, 1000 / 60);
        }
    }, 100);

    return <Sphere args={[charge, 32, 32]} position={charging ? chargePos : launchedPos} />
}

export default ChargeShot;