import React, { useEffect, useRef } from 'react'
import * as THREE from 'three';

import { useFrame, useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
let decisionTimeout = 1.3;

export default function PracticeBot({ selectedAction, position, rotation, setPosition, bottomleftPanelPosition, topRightPanelPosition, health, fire, naviPosition }) {
    const gltf = useLoader(GLTFLoader, '/models/bot_ranger_geo/scene.gltf');
    const mixerRef = useRef(null);
    const actionRef = useRef(null);
    const [actions, setActions] = React.useState({});

    useEffect(() => {
        const mixer = new THREE.AnimationMixer(gltf.scene)
        mixerRef.current = mixer;
        gltf.animations.forEach((clip) => {
            const action = mixer.clipAction(clip);
            setActions((prev) => ({ ...prev, [clip.name]: action }));
        });

        const action = mixer.clipAction(gltf.animations[1])
        action.play();
        actionRef.current = action;

        return () => {
            mixer.stopAllAction()
        }
    }, [gltf])

    function move(direction) {
        switch (direction) {
            case "up":
                if (position[2] > bottomleftPanelPosition[2]) {
                    setPosition((prev) => [prev[0], prev[1], prev[2] - 4]);
                }
                break;
            case "down":
                if (position[2] < topRightPanelPosition[2]) {
                    setPosition((prev) => [prev[0], prev[1], prev[2] + 4]);
                }
                break;
            case "left":
                if (position[0] > bottomleftPanelPosition[0]) {
                    setPosition((prev) => [prev[0] - 4, prev[1], prev[2]]);
                }
                break;
            case "right":
                if (position[0] < topRightPanelPosition[0]) {
                    setPosition((prev) => [prev[0] + 4, prev[1], prev[2]]);
                }
                break;
            default:
                break;
        }
    }

    function fireShot() {
        actions["bot_ranger_attack"].play();
        fire(() => {
            actions["bot_ranger_attack"].stop();
            actions["bot_ranger_idle"].play();
        });
    }


    useFrame((state, delta) => {
        if (mixerRef.current) {
            mixerRef.current.update(delta);
        }
        if (health > 0) {
            if (decisionTimeout <= 1.0) {
                const decision = Math.floor(Math.random() * 100);
                if (decision < 10) {
                    move("up");
                } else if (decision < 20) {
                    move("down");
                } else if (decision < 30) {
                    move("left");
                } else if (decision < 40) {
                    move("right");
                } else if (decision < 70) {
                    fireShot();
                } else if (decision < 90) {
                    //move in direction of player
                    const xDiff = naviPosition[0] - position[0];
                    if (xDiff > 2) {
                        move("right");
                    } else if (xDiff < -2) {
                        move("left");
                    }
                }
                decisionTimeout = 1.3;
            } else {
                decisionTimeout -= delta;
            }
        }
    })
    return <primitive object={gltf.scene} scale={1.5} position={position} rotation={rotation} />
}
