import React, { Fragment } from 'react';
import { Html } from '@react-three/drei';
import Select from 'react-select';
import moment from 'moment';
const actionOptions = [{
    label: "API Request",
    value: 'api',
}, {
    label: "Database Input",
    value: 'db',
}, {
    label: "Reminder",
    value: 'reminder',
}
];
function NaviReviewWorkshop({ navi, updateNavi, setActivePage, queryGPT3 }) {
    const naviPriorities = navi.priorities || {}


    const [activePlanPage, setActivePlanPage] = React.useState(0);
    const naviModules = naviPriorities[activePlanPage] || [];
    const [addOpen, setAddOpen] = React.useState(false);
    const [newPlan, setNewPlan] = React.useState({
        note: "",
    });
    const [activeDate, setActiveDate] = React.useState(new Date());

    return (<Html className="content" position={[0, 7, -10]} rotation={[0, 0, 0]} transform occlude>
        <div className="wrapper" onPointerDown={(e) => e.stopPropagation()} style={{ height: 600, display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
            <div style={{ Agendadisplay: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <h1 style={{ textAlign: 'center' }}>PRIORITIES</h1>

                <div className="flex aic jcsb" style={{ width: 500, marginBottom: 40 }}>
                    <i className="las la-chevron-left" style={{ color: '#00ffff' }}
                        onClick={() => setActiveDate(moment(activeDate).subtract(1, 'day').toDate())}
                    ></i>
                    <h3>{moment(activeDate).format("dddd M-D, Y")}</h3>
                    <i className="las la-chevron-right" style={{ color: '#00ffff' }}
                        onClick={() => setActiveDate(moment(activeDate).add(1, 'day').toDate())}
                    ></i>
                </div>
                <div className="flex aic jcc">
                    <button className="grow1 minWidth" onClick={() => setActivePlanPage(0)
                    }>
                        FAMILY
                    </button>
                    <button
                        className="grow1 minWidth"
                        onClick={() => setActivePlanPage(1)}
                    >INVESTMENTS</button>

                    <button
                        className="grow1 minWidth"
                        onClick={() => setActivePlanPage(1)}
                    >HEALTH</button>
                </div>
                {addOpen ? <div className="flex col jcc">
                    <div className='flex col' style={{ paddingTop: 20, paddingBottom: 40 }}>

                        <label>Plan</label>
                        <textarea
                            style={{ background: "#67dcff2e", color: 'white', fontSize: 20, padding: 10, borderRadius: 10, border: 'none', outline: 'none', borderTopLeftRadius: 0 }}
                            value={newPlan.note} onChange={(e) => {
                                setNewPlan({ ...newPlan, note: e.target.value });
                            }}></textarea>
                    </div>
                    <div className="mt-a mb-20 flex aic jcc">

                        <button className="grow1 minWidth" onClick={() => {
                            let tempModules = [...naviModules];
                            tempModules.push(newPlan);
                            const priorities = { ...naviPriorities, [activePlanPage]: tempModules };
                            const updatedNavi = { ...navi, priorities: priorities };
                            updateNavi(updatedNavi, () => {
                                setAddOpen(false);
                                setNewPlan({
                                    note: "",
                                });
                                setActivePage(<NaviReviewWorkshop navi={updatedNavi} updateNavi={updateNavi} setActivePage={setActivePage} queryGPT3={queryGPT3} />)
                            }, true);
                        }}>ADD</button>
                        <button
                            className="grow1 minWidth"
                            onClick={() => setAddOpen(false)}
                        >CANCEL</button>
                    </div>
                </div> : activePlanPage == 0 ? <Fragment>
                    <div className="flex col agendaContainer">
                        {naviModules.map((m, i) => {
                            return moment(m.when).format("M-D-Y") == moment(activeDate).format("M-D-Y") && (<div key={i} className="planContainer">
                                <div className="flex aic jcsb">
                                    <h3>{m.name}</h3>
                                    {m.action == 'reminder' ? <i className="las la-clock" style={{ color: '#00ffff' }}></i> : m.action == 'db' ? <i className="las la-database" style={{ color: '#00ffff' }}></i> : <i style={{ color: '#00ffff' }} className="las la-code"></i>}
                                </div>
                                <div className="flex aic jcsb mb-20">

                                    <p className="small">{m.goal}</p>
                                    {m.when ? <p className="small ml-a"> {moment(m.when).fromNow()}</p> : ''}
                                </div>
                                <div className="flex aic jcc">
                                    <button className="grow1 minWidth" onClick={() => {
                                        let tempModules = [...naviModules];
                                        tempModules.splice(i, 1);
                                        const priorities = { ...naviPriorities, [activePlanPage]: tempModules };
                                        const updatedNavi = { ...navi, priorities: priorities };
                                        updateNavi(updatedNavi, () => {
                                            setActivePage(<NaviReviewWorkshop navi={updatedNavi} updateNavi={updateNavi} setActivePage={setActivePage} queryGPT3={queryGPT3} />)
                                        }, true);
                                    }}>REMOVE</button>
                                    <button className="grow1 minWidth" onClick={() => {
                                        setAddOpen(true);
                                    }}>EDIT</button>
                                </div>

                            </div>)
                        }
                        )}
                    </div></Fragment> : <div className="flex col jcc"></div>}
            </div>
            {!addOpen && <button className="mb-20" onClick={() => {
                /* let tempModules = [...naviModules];
                tempModules.push({
                    name: "New Module",
                    description: "This is a new module",
                    tasks: []
                });
                const updatedNavi = { ...navi, modules: tempModules };
                updateNavi(updatedNavi, () => {
                    setActivePage(<NaviReviewWorkshop navi={updatedNavi} updateNavi={updateNavi} setActivePage={setActivePage} queryGPT3={queryGPT3} />)
                }, true); */
                setAddOpen(true);
            }}>ADD NEW</button>}
        </div>
    </Html>)
}

export default NaviReviewWorkshop;