import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

const CharAnimation = ({ text }) => {
    const titleRef = useRef(null);
    const CHAR_TIME = 60;
    let index;

    const [isAnimating, setIsAnimating] = useState(true);

    function requestCharAnimation(char, value) {
        setTimeout(function () {
            char.textContent = value;
            char.classList.add("fade-in");
        }, CHAR_TIME);
    }

    function addChar() {
        const char = document.createElement("span");
        char.classList.add("char");
        char.textContent = "▌";
        titleRef.current.appendChild(char);
        requestCharAnimation(char, text.substr(index++, 1));
        if (index < text.length) {
            requestChar();
        } else {
            setIsAnimating(false);
        }
    }

    function requestChar(delay = 0) {
        setTimeout(addChar, CHAR_TIME + delay);
    }

    useEffect(() => {
        index = 0;
        const title = titleRef.current;
        const textContent = title.textContent.trim();
        title.textContent = "";
        requestChar(1000);

        return () => {
            // Clean up the animation on unmount
            title.textContent = textContent;
            title.querySelectorAll('.char').forEach(char => {
                char.classList.remove('fade-in');
                char.textContent = '';
            });
        };
    }, []);

    return (
        <span ref={titleRef} className={`${!isAnimating ? 'fadeOut' : ''}`}>
            {/* The text prop is not used, as the animation is based on the h1 content */}
        </span>
    );
};

CharAnimation.propTypes = {
    text: PropTypes.string.isRequired,
};

export default CharAnimation;
