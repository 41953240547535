import React, { Component } from "react";
import ExpandingMenu from "../Components/ExpandingMenu";
import CharAnimation from "../Components/TextAnimator";
import Recorder from "../Components/Recorder";
import { Html } from "@react-three/drei";
import { CirclePicker } from "react-color";
import Select from "react-select";
import moment from "moment";
import { Fragment } from "react";
import NaviReviewWorkshop from "./Modules/ReviewWorkshop";
import charge from "../Assets/Sounds/charge.mp3";
import { Howl } from "howler";

const chargeSound = new Howl({
    src: [charge],
    loop: true,
    preload: true,
    autoDecode: true,
});

let chargeTimeout = null;

const voiceOptions = [
    {
        label: "Male English",
        value: "ai3-en-US-Austin",

        LanguageCode: "en-US",
    },
    {
        label: "Female English",
        value: "ai3-en-US-Sage",
        LanguageCode: "en-US",
    },
    {
        label: "Male Spanish",
        value: "ai3-es-SV-Mateo",
        LanguageCode: "es-SV",
    },
    {
        label: "Female Spanish",
        value: "ai3-es-SV-Juana",
        LanguageCode: "es-SV",
    },
];
const actionOptions = [
    {
        label: "API Request",
        value: "api",
    },
    {
        label: "Database Input",
        value: "db",
    },
    {
        label: "Reminder",
        value: "reminder",
    },
];
let connectionInterval = null;
const introduction =
    "Hello, I am your network navigator, or net navi for short. I am here to help you navigate the cyber network. We are currently in the hub; from here you can customize how I look, train to work together, configure our experience, and explore the vast cyber network. I am here for you to talk and help with anything you can think of, use the tools at the bottom of your PErsonal Terminal (P E T) to communicate with me. Let's get started! tell me about yourself.";

const networkPages = [
    {
        title: "Practice Arena",
        description: "Practice your skills in a safe environment",
        value: "arena",
    },
    {
        title: "Chip Shop",
        description: "Buy and sell chips",
        value: "chipshop",
    },
    {
        title: "Battle Network",
        description: "Battle other navis",
        value: "battlenetwork",
    },
    {
        title: "Cyber Square",
        description: "Hang out with other navis",
        value: "cybersquare",
    },
];

function NaviModuleWorkshop({ navi, updateNavi, setActivePage, queryGPT3 }) {
    const naviModules = navi.modules || [];
    const [activePlanPage, setActivePlanPage] = React.useState(0);
    const [addOpen, setAddOpen] = React.useState(false);
    const [newPlan, setNewPlan] = React.useState({
        name: "",
        goal: "",
    });
    const [activeDate, setActiveDate] = React.useState(new Date());

    return (
        <Html className="content" position={[0, 7, 5]} rotation={[0, -Math.PI / 2, 0]} transform occlude>
            <div className="wrapper" onPointerDown={(e) => e.stopPropagation()} style={{ height: 600, display: "flex", flexDirection: "column", padding: "0 20px" }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <h1>Planner</h1>
                    <div className="flex aic jcc">
                        <button className="grow1 minWidth" onClick={() => setActivePlanPage(0)}>
                            Agenda
                        </button>
                        <button className="grow1 minWidth" onClick={() => setActivePlanPage(1)}>
                            List
                        </button>
                    </div>
                    {addOpen ? (
                        <div className="flex col jcc">
                            <label>Plan</label>
                            <input
                                value={newPlan.name}
                                onChange={(e) => {
                                    setNewPlan({ ...newPlan, name: e.target.value });
                                }}
                            ></input>
                            <label>Goal</label>
                            <input
                                value={newPlan.goal}
                                onChange={(e) => {
                                    setNewPlan({ ...newPlan, goal: e.target.value });
                                }}
                            ></input>
                            <label>Action</label>
                            <div className="flex aic jcc">
                                <Select
                                    options={actionOptions}
                                    onChange={(e) => {
                                        setNewPlan({ ...newPlan, action: e.value });
                                    }}
                                />
                            </div>
                            <label>When</label>
                            <div className="flex aic">
                                <button className="minWidth" onClick={() => setNewPlan({ ...newPlan, when: newPlan.when ? null : new Date() })}>
                                    {newPlan.when ? "Now" : "Later"}
                                </button>
                                {newPlan.when && (
                                    <div style={{ padding: "0 10px" }}>
                                        <p className="small">Date and time</p>
                                        <input
                                            type="datetime-local"
                                            value={newPlan.when}
                                            onChange={(e) => {
                                                setNewPlan({ ...newPlan, when: e.target.value });
                                            }}
                                        ></input>
                                    </div>
                                )}
                                {!newPlan.when && (
                                    <div>
                                        <p className="small">Repeat</p>
                                        <Select
                                            value={newPlan.repeat ? { label: newPlan.repeat, value: newPlan.repeat } : null}
                                            options={[
                                                { label: "None", value: null },
                                                { label: "Daily", value: "daily" },
                                                { label: "Weekly", value: "weekly" },
                                                { label: "Monthly", value: "monthly" },
                                                { label: "Yearly", value: "yearly" },
                                            ]}
                                            onChange={(e) => {
                                                setNewPlan({ ...newPlan, repeat: e.value });
                                            }}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="mt-a mb-20 flex aic jcc">
                                <button
                                    className="grow1 minWidth"
                                    onClick={() => {
                                        let tempModules = [...naviModules];
                                        tempModules.push(newPlan);
                                        const updatedNavi = { ...navi, modules: tempModules };
                                        updateNavi(
                                            updatedNavi,
                                            () => {
                                                setAddOpen(false);
                                                setNewPlan({
                                                    name: "",
                                                    goal: "",
                                                });
                                                setActivePage(
                                                    <NaviModuleWorkshop navi={updatedNavi} updateNavi={updateNavi} setActivePage={setActivePage} queryGPT3={queryGPT3} />
                                                );
                                            },
                                            true
                                        );
                                    }}
                                >
                                    ADD
                                </button>
                                <button className="grow1 minWidth" onClick={() => setAddOpen(false)}>
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    ) : activePlanPage == 0 ? (
                        <Fragment>
                            <div className="flex aic jcsb mt-20" style={{ width: 500 }}>
                                <i className="las la-chevron-left" style={{ color: "#00ffff" }} onClick={() => setActiveDate(moment(activeDate).subtract(1, "day").toDate())}></i>
                                <h3>{moment(activeDate).format("dddd M-D, Y")}</h3>
                                <i className="las la-chevron-right" style={{ color: "#00ffff" }} onClick={() => setActiveDate(moment(activeDate).add(1, "day").toDate())}></i>
                            </div>
                            <div className="flex col agendaContainer">
                                {naviModules.map((m, i) => {
                                    return (
                                        moment(m.when).format("M-D-Y") == moment(activeDate).format("M-D-Y") && (
                                            <div key={i} className="planContainer">
                                                <div className="flex aic jcsb">
                                                    <h3>{m.name}</h3>
                                                    {m.action == "reminder" ? (
                                                        <i className="las la-clock" style={{ color: "#00ffff" }}></i>
                                                    ) : m.action == "db" ? (
                                                        <i className="las la-database" style={{ color: "#00ffff" }}></i>
                                                    ) : (
                                                        <i style={{ color: "#00ffff" }} className="las la-code"></i>
                                                    )}
                                                </div>
                                                <div className="flex aic jcsb mb-20">
                                                    <p className="small">{m.goal}</p>
                                                    {m.when ? <p className="small ml-a"> {moment(m.when).fromNow()}</p> : ""}
                                                </div>
                                                <div className="flex aic jcc">
                                                    <button
                                                        className="grow1 minWidth"
                                                        onClick={() => {
                                                            let tempModules = [...naviModules];
                                                            tempModules.splice(i, 1);
                                                            const updatedNavi = { ...navi, modules: tempModules };
                                                            updateNavi(
                                                                updatedNavi,
                                                                () => {
                                                                    setActivePage(
                                                                        <NaviModuleWorkshop
                                                                            navi={updatedNavi}
                                                                            updateNavi={updateNavi}
                                                                            setActivePage={setActivePage}
                                                                            queryGPT3={queryGPT3}
                                                                        />
                                                                    );
                                                                },
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        REMOVE
                                                    </button>
                                                    <button
                                                        className="grow1 minWidth"
                                                        onClick={() => {
                                                            setAddOpen(true);
                                                        }}
                                                    >
                                                        EDIT
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        </Fragment>
                    ) : (
                        <div className="flex col jcc"></div>
                    )}
                </div>
                {!addOpen && (
                    <button
                        className="mt-a mb-20"
                        onClick={() => {
                            /* let tempModules = [...naviModules];
                tempModules.push({
                    name: "New Module",
                    description: "This is a new module",
                    tasks: []
                });
                const updatedNavi = { ...navi, modules: tempModules };
                updateNavi(updatedNavi, () => {
                    setActivePage(<NaviModuleWorkshop navi={updatedNavi} updateNavi={updateNavi} setActivePage={setActivePage} queryGPT3={queryGPT3} />)
                }, true); */
                            setAddOpen(true);
                        }}
                    >
                        ADD PLAN
                    </button>
                )}
            </div>
        </Html>
    );
}

let touchRef = new React.createRef();
let touchIndicatorRef = new React.createRef();
let touchStart = null;
let touchStartTime = null;
let movedUp = false;
let movedDown = false;
let movedLeft = false;
let movedRight = false;
let touchMoved = false;
let fired = false;
let charged = false;
let isCharging = false;

class HUD extends Component {
    state = {
        isProcessing: false,
        menuOpen: false,
        chatOpen: false,
        voiceOpen: false,
        configsOpen: false,
        inCustomize: false,
        inExplore: false,
        inTrain: false,
        inTalk: true,
        inConfigure: false,
        inNaviSetup: false,
        inAuth: false,
        subtitle: "",
        memoryOpen: false,
    };

    authenticate = () => {
        const { setCameraLookAtTarget, setCameraPosition } = this.props;
        setCameraLookAtTarget([0, 5, 5]);
        setCameraPosition([0, 5, -5]);
        this.setState({ inAuth: true });
        connectionInterval = setInterval(() => {
            const { isConnected } = this.props;
            if (isConnected) {
                clearInterval(connectionInterval);
                this.navigateToNaviSetup();
            }
        }, 500);
    };

    NaviCustomization = () => {
        const { navi, updateNavi, setActivePage } = this.props;

        return (
            <Html className="content" position={[0, 6, -10]} transform occlude>
                <div className="wrapper" onPointerDown={(e) => e.stopPropagation()} style={{ height: 500, display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ width: "100%", marginBottom: 25 }}>
                            <label>Voice</label>
                            <Select
                                options={voiceOptions}
                                value={voiceOptions.find((vo) => vo.value == navi.VoiceId)}
                                onChange={(e) => {
                                    updateNavi({ ...navi, VoiceId: e.value, LanguageCode: e.LanguageCode }, () => setActivePage(this.NaviCustomization));
                                }}
                            />
                        </div>
                        <div>
                            <label>Color</label>
                            <CirclePicker
                                color={navi.color}
                                onChangeComplete={(e) => {
                                    updateNavi({ ...navi, color: e.hex }, () => setActivePage(this.NaviCustomization));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Html>
        );
    };

    NetworkExplorer = () => {
        return (
            <Html className="content" position={[0, 10, 20]} rotation={[-Math.PI / 21, -Math.PI, 0]} transform occlude>
                <div
                    className="wrapper"
                    onPointerDown={(e) => e.stopPropagation()}
                    style={{ height: 500, width: "clamp(600px, 100vw, 1200px)", display: "flex", flexDirection: "column" }}
                >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
                        {networkPages.map((p, i) => {
                            return (
                                <div key={i} className="containerCard flex col aic" style={{ width: 250, height: 300, margin: 10, textAlign: "center" }}>
                                    <h3 className="mb-10">{p.title}</h3>
                                    <p>{p.description}</p>
                                    <button
                                        className="mt-a"
                                        onClick={() => {
                                            //navigate to page
                                            this.goToArea(p.value);
                                        }}
                                    >
                                        GO
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Html>
        );
    };

    goToArea = (area) => {
        const { setArea, setActivePage, setCurrentAction, setCameraLookAtTarget, setCameraPosition } = this.props;
        setArea(area);
        setActivePage(null);
        const screenW = window.innerWidth;
        //300 = -45,  1200 = -25
        //do some math to get the camera x position
        let x = -45;
        if (screenW > 400 && screenW < 1000) {
            x = -30;
        } else if (screenW > 1000) {
            x = -15;
        }
        console.log("far", x);
        setCameraPosition([x, 8, 6.125]);
        setCameraLookAtTarget([0, 1, 6.125]);
        setCurrentAction(area.toUpperCase());
    };

    navigateToNaviSetup = () => {
        const { setCameraLookAtTarget, setCameraPosition } = this.props;
        setCameraLookAtTarget([0, 3, 5]);
        setCameraPosition([0, 3, -5]);
        this.setState({ isWaiting: true, inNaviSetup: true, inAuth: false });

        connectionInterval = setInterval(() => {
            const { navi } = this.props;
            if (navi.userId) {
                this.setState({ isWaiting: false });
                clearInterval(connectionInterval);
                this.navigateToTalk();
                this.speak(introduction, () => {
                    this.setState({ subtitle: introduction, isProcessing: false });
                });
            }
        }, 500);
    };

    navigateToCustomization = () => {
        const { setCameraLookAtTarget, setCameraPosition, setActivePage, setCurrentAction } = this.props;

        setCameraPosition([0, 3, 15]);
        setCameraLookAtTarget([0, 3, -5]);
        setActivePage(this.NaviCustomization);
        setCurrentAction("Customizing");
        this.setState({ inCustomize: true, inTalk: false, inExplore: false, inTrain: false, inConfigure: false });
    };

    navigateToExplore = () => {
        const { setCameraLookAtTarget, setCameraPosition, setActivePage, setCurrentAction } = this.props;
        setCameraPosition([0, 3, -10]);
        setCameraLookAtTarget([0, 5, 15]);
        setActivePage(this.NetworkExplorer);
        setCurrentAction("Exploring");
        this.setState({ inExplore: true, inTalk: false, inCustomize: false, inTrain: false, inConfigure: false });
    };

    navigateToTrain = () => {
        const { setCameraLookAtTarget, setCameraPosition, setActivePage, updateNavi, queryGPT3, navi, setCurrentAction } = this.props;
        setCameraPosition([-25, 5, 5]);
        setCameraLookAtTarget([0, 5, 5]);
        setCurrentAction("Planning");
        setActivePage(<NaviModuleWorkshop navi={navi} updateNavi={updateNavi} setActivePage={setActivePage} queryGPT3={queryGPT3} />);
        this.setState({ inTrain: true, inTalk: false, inCustomize: false, inExplore: false, inConfigure: false });
    };

    navigateToTalk = () => {
        const { setCameraLookAtTarget, setCameraPosition, setActivePage, setCurrentAction } = this.props;

        setCameraPosition([0, 3, 3]);
        setCameraLookAtTarget([0, 3, 0.5]);
        setActivePage(null);
        setCurrentAction("Talking");

        this.setState({ inTalk: true, inCustomize: false, inExplore: false, inTrain: false, inConfigure: false });
    };

    navigateToConfigure = () => {
        const { setCameraLookAtTarget, setCameraPosition, setActivePage, updateNavi, queryGPT3, navi, setCurrentAction } = this.props;

        setCameraPosition([0, 3, 15]);
        setActivePage(<NaviReviewWorkshop navi={navi} updateNavi={updateNavi} setActivePage={setActivePage} queryGPT3={queryGPT3} />);
        setCurrentAction("Reviewing");
        this.setState({ inConfigure: true, inTalk: false, inCustomize: false, inExplore: false, inTrain: false });
    };

    componentDidMount() {
        const { inCustomize, inExplore, inTrain, inTalk, inConfigure } = this.state;
        if (inCustomize) {
            this.navigateToCustomization();
        } else if (inExplore) {
            this.navigateToExplore();
        } else if (inTrain) {
            this.navigateToTrain();
        } else if (inTalk) {
            //this.navigateToTalk();
        } else if (inConfigure) {
            this.navigateToConfigure();
        }

        //handle touch events
        touchRef.current.addEventListener("touchstart", this.handleTouch, false);
        touchRef.current.addEventListener("touchmove", this.handleTouch, false);
        touchRef.current.addEventListener("touchend", this.handleTouch, false);
    }

    componentWillUnmount() {
        touchRef.current.removeEventListener("touchstart", this.handleTouch, false);
        touchRef.current.removeEventListener("touchmove", this.handleTouch, false);
        touchRef.current.removeEventListener("touchend", this.handleTouch, false);
    }

    speak = (text, cb) => {
        const { navi } = this.props;
        if (text == "") {
            cb && cb();
            return;
        }
        const payload = {
            Engine: "neural",
            VoiceId: navi.VoiceId,
            LanguageCode: navi.LanguageCode,
            /* VoiceId: 'ai3-es-SV-Mateo',
            LanguageCode: 'es-US', */
            Text: text,
            OutputFormat: "mp3",
            SampleRate: "48000",
            Effect: "default",
            MasterSpeed: "0",
            MasterVolume: "0",
            MasterPitch: "0",
        };
        const http = new XMLHttpRequest();
        const jwtoken = "b93f3780-e4a8-11ed-95ef-8fec4cbdbc66";
        http.open("POST", "https://developer.voicemaker.in/voice/api");
        //append bearer token to headers
        http.setRequestHeader("Authorization", "Bearer " + jwtoken);
        //set content type to json
        http.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        http.send(JSON.stringify(payload));
        http.onload = async function () {
            //parse an play audio
            const res = JSON.parse(http.responseText);
            const audio = new Audio(res.path);
            audio.play();
            cb && cb();
        };
    };

    think = async () => {
        console.log("thinking");
        //const passThougths = window.user
        let passThoughts = localStorage.getItem("passThoughts");
        const prompt =
            "This is a story about a being. This being percieves the world through text inputs it finds thoughts in the text, shaping its reality. The being is a Navi. Take on the role of the Navi and explore the world through text based on this snippet of past thoughts limited to only 2000 characters.[Start Pass Thoughts] " +
            passThoughts +
            "[End Pass Thoughts].To speak a thought, wrap in [Start Speak][End Speak]. Keep speaking to a minimum and work on analyzing the thoughts you find.";

        this.props.queryGPT3(prompt, (response) => {
            const reply = response.choices[0].text;
            passThoughts += reply;
            localStorage.setItem("passThoughts", passThoughts);

            if (reply.includes("[Start Speak]") && reply.includes("[End Speak]")) {
                const speakText = reply.split("[Start Speak]")[1].split("[End Speak]")[0];
                this.speak(speakText, () => {
                    this.setState({ thoughts: reply, isThinking: false });
                });
            } else {
                this.setState({ thoughts: reply, isThinking: false });
            }
            setTimeout(() => {
                this.think();
            }, 30000);
        });
    };

    handleMove = (e) => {
        const { setForwardPressed, setBackwardPressed, setTurnLeftPressed, setTurnRightPressed, move } = this.props;
        // if y > .5 move forward if x > .5 turn right
        if (e.y > 0.5) {
            setForwardPressed(true);
            setBackwardPressed(false);
            move(8);
        } else if (e.y < -0.5) {
            setBackwardPressed(true);
            setForwardPressed(false);
            move(8);
        } else {
            setBackwardPressed(false);
            setForwardPressed(false);
            move(0);
        }
        if (e.x > 0.25) {
            setTurnRightPressed(true);
            setTurnLeftPressed(false);
        } else if (e.x < -0.25) {
            setTurnLeftPressed(true);
            setTurnRightPressed(false);
        } else {
            setTurnLeftPressed(false);
            setTurnRightPressed(false);
        }
    };

    handleStop = (e) => {
        const { setForwardPressed, setBackwardPressed, setTurnLeftPressed, setTurnRightPressed, move } = this.props;
        setBackwardPressed(false);
        setForwardPressed(false);
        setTurnLeftPressed(false);
        setTurnRightPressed(false);
        move(0);
    };

    //handle swipes
    //from cener if touch moves 10 from center move up, set move up to true, if touch moves back to center set move up to false
    //do the same with down, left, and right
    //single touch with no move fires shot
    //double touch with no move charges shot and allows movement
    //if double touched and and touch end is 10 from center fire charged shot, else fire normal shot
    handleTouch = (e) => {
        const { audioRef } = this.props;
        const now = new Date();
        switch (e.type) {
            case "touchstart":
                touchStart = e.touches[0];
                touchStartTime = now;
                isCharging = true;
                touchIndicatorRef.current.classList.add("active");
                touchIndicatorRef.current.style.left = `${touchStart.clientX - 15}px`;
                touchIndicatorRef.current.style.top = `${touchStart.clientY - 15}px`;
                chargeTimeout = setTimeout(() => {
                    if (isCharging) {
                        chargeSound.play();
                    }
                }, 250);
                break;
            case "touchmove":
                const touch = e.touches[0];
                const x = touch.clientX;
                const y = touch.clientY;
                const centerX = touchStart.clientX;
                const centerY = touchStart.clientY;
                if (x > centerX + 30 && !movedRight && !(movedUp || movedDown)) {
                    movedRight = true;
                    this.props.move("right");
                } else if (x < centerX - 30 && !movedLeft && !(movedUp || movedDown)) {
                    movedLeft = true;
                    this.props.move("left");
                } else if (y > centerY + 30 && !movedDown && !(movedLeft || movedRight)) {
                    movedDown = true;
                    this.props.move("down");
                } else if (y < centerY - 30 && !movedUp && !(movedLeft || movedRight)) {
                    movedUp = true;
                    this.props.move("up");
                } else if (x < centerX + 30 && x > centerX - 30 && y < centerY + 30 && y > centerY - 30) {
                    //touch is back in center
                    movedUp = false;
                    movedDown = false;
                    movedLeft = false;
                    movedRight = false;
                }
                if (movedUp || movedDown || movedLeft || movedRight) {
                    touchIndicatorRef.current.classList.add("inactive");
                } else {
                    touchIndicatorRef.current.classList.remove("inactive");
                }
                touchMoved = true;
                break;
            case "touchend":
                chargeSound.stop();
                const timeDiff = now - touchStartTime;
                touchIndicatorRef.current.classList.remove("active");
                touchIndicatorRef.current.classList.remove("inactive");
                if (timeDiff < 250 && touchMoved) {
                    //reset
                    touchMoved = false;
                    isCharging = false;
                    fired = false;
                    charged = false;
                    movedUp = false;
                    movedDown = false;
                    movedLeft = false;
                    movedRight = false;
                    touchStart = null;
                    touchStartTime = null;
                    return;
                } else if (timeDiff < 250 && !touchMoved) {
                    //fire shot
                    if (!fired) {
                        fired = true;
                        this.props.fireShot(false, () => (fired = false));
                    }
                    //reset
                    touchMoved = false;
                    isCharging = false;
                    charged = false;
                    movedUp = false;
                    movedDown = false;
                    movedLeft = false;
                    movedRight = false;
                    touchStart = null;
                    touchStartTime = null;
                    return;
                } else if (timeDiff > 250) {
                    if (!fired) {
                        fired = true;
                        this.props.fireShot(timeDiff > 5000, () => (fired = false));
                    }
                    //reset
                    touchMoved = false;
                    isCharging = false;
                    charged = false;
                    movedUp = false;
                    movedDown = false;
                    movedLeft = false;
                    movedRight = false;
                    touchStart = null;
                    touchStartTime = null;
                    return;
                }

                break;
        }
    };

    exitToHub = () => {
        const { setArea, setActivePage, setCurrentAction, setCameraLookAtTarget, setCameraPosition, resetBattle } = this.props;
        setArea("HUB");
        setActivePage(null);
        setCameraPosition([0, 3, 3]);
        setCameraLookAtTarget([0, 3, 0.5]);
        setCurrentAction("Talking");
        resetBattle();
        this.setState({ inTalk: true, inCustomize: false, inExplore: false, inTrain: false, inConfigure: false });
    };

    restartBattle = () => {
        const { resetBattle } = this.props;
        resetBattle();
    }

    render() {
        const { isReady, isConnected, navi, queryChatGPT, currentAction, area, naviHealth, enemyHealth, battleOver } = this.props;
        const { chatOpen, voiceOpen, configsOpen, subtitle, message, isProcessing, inCustomize, inExplore, inTrain, inTalk, inConfigure, inNaviSetup, inAuth, memoryOpen } =
            this.state;
        const menuButtons = [];
        if (!inCustomize) {
            menuButtons.push({ text: "Customize", onClick: this.navigateToCustomization });
        }
        if (!inExplore) {
            menuButtons.push({ text: "Battle", onClick: this.navigateToExplore });
        }
        if (!inTrain) {
            menuButtons.push({ text: "Plan", onClick: this.navigateToTrain });
        }
        if (!inTalk) {
            menuButtons.push({ text: "Talk", onClick: this.navigateToTalk });
        }

        if (!inConfigure) {
            menuButtons.push({ text: "Chips", onClick: this.navigateToConfigure });
        }

        return (
            <section style={{ pointerEvents: isReady ? "all" : "none" }}>
                <div className={`touchArea ${area == "arena" ? "" : "hidden"}`} ref={touchRef} onContextMenu={(e) => e.preventDefault()}>
                    <div className="touchCenterIndicator" ref={touchIndicatorRef}></div>
                    {battleOver && (
                        <div style={{ background: "#00ffff10", padding: 20, marginTop: "35vh", marginLeft: 20, marginRight: 20 }}>
                            <div className="wrapper" onPointerDown={(e) => e.stopPropagation()} style={{ display: "flex", flexDirection: "column" }}>
                                <div>
                                    <h1>Battle Over</h1>
                                    <h3 className="mb-20">{naviHealth <= 0 ? "You Lost" : "You Won"}</h3>
                                    <button className="glowButton" onClick={this.restartBattle}>
                                        RESTART
                                    </button>
                                    <button onClick={this.exitToHub}>
                                        EXIT
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <header className={`fadeIn ${isReady ? "ready " : ""}`}>
                    <div className="menuBar ">
                        <div>{/* <a href="#">LINK</a> */}</div>
                        <div className="posRel w-100">
                            <h2 className="headerBox">{area}</h2>

                            {area == "HUB" && isConnected && navi.userId && (
                                <div className="areaTitle">
                                    <h4>{currentAction}</h4>
                                </div>
                            )}
                            {area !== "HUB" && (
                                <div className="areaExit">
                                    <h4 onClick={this.exitToHub}>EXIT</h4>
                                </div>
                            )}
                            {area == "arena" && (
                                <div className="healthBarsContainer">
                                    <div>{naviHealth}</div>
                                    <div>{enemyHealth}</div>
                                </div>
                            )}
                        </div>
                        <div></div>
                    </div>
                </header>
                <footer className={` fadeIn ${isReady ? "ready" : ""}`}>
                    {isReady && (
                        <div className="subtitles" style={{ width: "100%" }}>
                            <p>{/* <TextResolver strings={["Start exploring the cyber world by setting up a network navigator."]} /> */}</p>
                        </div>
                    )}
                    {isReady && (
                        <div className="menuContainer">
                            {!isConnected && !inAuth && (
                                <button className="cutCornerMenuButton" onClick={this.authenticate}>
                                    CONNECT
                                </button>
                            )}
                            {isConnected && !navi.userId && !inNaviSetup && (
                                <button className="cutCornerMenuButton" onClick={this.navigateToNaviSetup}>
                                    SET UP NET NAVI
                                </button>
                            )}
                            {isConnected && isReady && navi.userId && (
                                <div style={{ position: "absolute", top: "calc((clamp(30px, 10vh, 70px)) * -1)" }}>
                                    <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
                                        {memoryOpen && (
                                            <div className="containerCard float">
                                                <h2 className="mb-20">Memory Log</h2>
                                                {navi.conversation.map((c, i) => {
                                                    return (
                                                        <div key={i} className="w-100 mb-10 flex col">
                                                            <label>{moment(c.timestamp).format("MM/DD/YY HH:MM a")}</label>
                                                            <p
                                                                className="small"
                                                                onClick={() => {
                                                                    //copy to clipboard
                                                                    navigator.clipboard.writeText(c.content);
                                                                    alert("Copied to clipboard");
                                                                }}
                                                            >
                                                                {c.content}
                                                            </p>
                                                            <label>{c.role}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <p className={`subtitle`}>
                                            {/* <TextResolver strings={["This is going to be text that the navi will be speaking, basically subtitles. Ok I have to make this a bit longer because I need to make usre that long responses do not look weird"]} /> */}
                                            {subtitle != "" && <CharAnimation text={subtitle} />}
                                        </p>
                                        {area !== "arena" && (
                                            <>
                                                <ExpandingMenu menuButtons={menuButtons} />
                                                <p className="small nowrap" style={{ position: "absolute", bottom: "clamp(-110px, -5vh, -83px)", textTransform: "uppercase" }}>
                                                    1 millenium
                                                </p>
                                                <h3 style={{ position: "absolute", bottom: "clamp(-110px, -15vh, -80px)" }}>{navi.name}</h3>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            {isConnected && navi.userId && (
                                <div className={`naviInputContainer ${chatOpen || voiceOpen || configsOpen ? "open" : ""}`}>
                                    <div className={`naviInput text ${chatOpen ? "open" : ""}`}>
                                        <i onClick={() => this.setState({ chatOpen: !this.state.chatOpen })} className="las la-sms"></i>
                                        <div className={`inputContainer `}>
                                            <input onChange={(e) => this.setState({ message: e.target.value })} value={message}></input>
                                            {isProcessing ? (
                                                <span className="thinking"></span>
                                            ) : (
                                                <button
                                                    className="sendButton"
                                                    style={{ marginLeft: 20 }}
                                                    onClick={() => {
                                                        if (message.length > 0) {
                                                            this.setState({ subtitle: "", message: "", isProcessing: true });
                                                            const m = message;
                                                            queryChatGPT(m, (response) => {
                                                                this.speak(response, () => {
                                                                    this.setState({ subtitle: response, isProcessing: false });
                                                                });
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Send
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`naviInput voice ${voiceOpen ? "open" : ""}`}>
                                        <Recorder
                                            stopContent={<i className="las la-stop-circle"></i>}
                                            idleContent={<i className="las la-microphone"></i>}
                                            recordingContent={<i className="las la-podcast"></i>}
                                            callback={(message, cb) => {
                                                if (message.length > 0) {
                                                    this.setState({ subtitle: "", message: "", isProcessing: true });
                                                    const m = message;
                                                    queryChatGPT(m, (response) => {
                                                        this.speak(response, () => {
                                                            this.setState({ subtitle: response, isProcessing: false });
                                                        });
                                                        cb && cb();
                                                    });
                                                }
                                            }}
                                        />
                                        <div className={`inputContainer`}></div>
                                    </div>
                                    <div className={`naviInput config ${configsOpen ? "open" : ""}`}>
                                        <div className={`inputContainer`}>
                                            <i
                                                className="las la-copy"
                                                onClick={() => {
                                                    //copy response to clipboard
                                                    navigator.clipboard.writeText(subtitle);
                                                    window.alert("Copied to clipboard!");
                                                }}
                                            ></i>
                                            <i
                                                className={`las la-book${memoryOpen ? "" : "-open"}`}
                                                onClick={() => {
                                                    // open memory
                                                    this.setState({ memoryOpen: !this.state.memoryOpen });
                                                }}
                                            ></i>
                                        </div>
                                        <i onClick={() => this.setState({ configsOpen: !this.state.configsOpen })} className="las la-cogs"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </footer>
            </section>
        );
    }
}

export default HUD;
