/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: IVZ4 (https://sketchfab.com/IVZ4)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/galaxian-space-warrior-4124025175ac4ad18add06f94f7d27b2
Title: Galaxian Space Warrior
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Warrior(props) {
  const group = useRef()
    const { nodes, materials, animations } = useGLTF('/models/galaxian_space_warrior/scene (1).gltf')
    const updatedGalaxianMaterial = materials.GalaxianMaterial;
    //green color
    updatedGalaxianMaterial.color = {r: 1, g: 1, b: 1};

  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null} scale={.01}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="3f51924bd76b42d69a175635801e7674fbx" rotation={[Math.PI / 2, 0, 0]}>
            <group name="Object_2">
              <group name="RootNode">
                <group name="Object_4">
                  <primitive object={nodes._rootJoint} />
                  <skinnedMesh
                    name="Object_7"
                    geometry={nodes.Object_7.geometry}
                    material={updatedGalaxianMaterial}
                    skeleton={nodes.Object_7.skeleton}                  
                  />
                  <group name="Object_6" rotation={[-Math.PI / 2, 0, 0]} scale={2.54} />
                  <group name="Galaxian" rotation={[-Math.PI / 2, 0, 0]} scale={2.54} />
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/galaxian_space_warrior/scene (1).gltf')
