import React from "react";
import { Fragment } from "react";

class ExpandingMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    render() {
        const { expanded } = this.state;
        const { menuButtons } = this.props;
        return (
            <Fragment>
                {menuButtons.map((button, i) => {
                    if (i > 3) return null;
                    return (
                        <button
                            className={`menuButton cutCorners ${expanded ? (i == 0 ? "topLeft" : i == 1 ? "topRight" : i == 2 ? "bottomLeft" : i == 3 ? "bottomRight" : "") : ""}`}
                            onClick={() => {
                                button.onClick();
                                this.setState({ expanded: false });
                            }}
                        >
                            {button.text}
                        </button>
                    );
                })}
                <button className="menuButton cutCorners on" onClick={() => this.setState({ expanded: !this.state.expanded })}>
                    Actions
                </button>
            </Fragment>
        );
    }
}

export default ExpandingMenu;